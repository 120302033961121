import { render, staticRenderFns } from "./managerights.html?vue&type=template&id=7ef4d665&scoped=true&"
import script from "./managerights.vue?vue&type=script&lang=js&"
export * from "./managerights.vue?vue&type=script&lang=js&"
import style0 from "./managerights.css?vue&type=style&index=0&id=7ef4d665&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef4d665",
  null
  
)

export default component.exports