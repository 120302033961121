<template src="./managerights.html"></template>
<style scoped src="./managerights.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import { mapActions, mapGetters } from 'vuex'
import ACLService from '@/services/ACLService'
import { required, email } from 'vuelidate/lib/validators'
import Utils from '@/utils/utils'
import modal from '@/components/modal/notification/modal'
import pagination from '@/components/vtable/vtpagination'
import AppConstants from '../../../constants/AppConstants.vue'

export default {
    components : {
        Loading, modal, pagination
    },
    data () {
        return {
            rightsList : [],        //rights List from server
            userList : [],          //user list from server
            groupList: [],          //groups list from server
            groupRightsList: [],    //groupRights list from server
            userId: null,           //userId 
            userEmail: null,        //Email binded to UserEmail textbox
            selectedGroup: null,    //group selected in Group dropdown
            isActivatedUser: null,  //checkbox 
            showAddButton : true,   //used for showing Add or update button
            columns: ['UserRole', 'UserEmail', 'GroupName', 'IsActivated', 'Action'],
            options : {
                headings : {
                    UserRole : 'User Code',
                    UserEmail : 'User Email',
                    GroupName : 'User Group',
                    IsActivated: 'Activated'
                }
            },
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            isLazyLoadingEnabled: AppConstants.enableLazyLoading
        }
    },
    methods : {
        ...mapActions(['fetchRightsList','fetchUserList','fetchUserGroupList','fetchGroupList', 'fetchGroupRightsList']),
        async loadTableData () {
            // fetch data from json-server
            await this.fetchUserList()
            await this.fetchUserGroupList()
            await this.fetchGroupList()
            this.userGroupList = await this.getUserGroupList
            this.groupList = await this.getGroupList
            this.userList = await this.getUserList.filter(ul=> ul.UserEmail !== this.$session.get('userEmail'))
        },
        addUser () {
            this.$v.$touch()
            if(this.$v.$invalid) {
                return
            }
            //modal 
            this.$bvModal.msgBoxConfirm('Are you sure you want to Add the User Permission ?')
            .then(async result => {
                if(result) {
                    //check user is already present or not
                    if(!this.userList.find(item => item.UserEmail === this.userEmail.trim())) {
                        this.loaderVisible = true
                        // Add User
                        let userId = Utils.methods.uuidv4()
                        await ACLService.methods.addUser({
                            "id": Utils.methods.uuidv4(),
                            "UserID": userId,
                            "UserEmail": this.userEmail.trim(),
                            "UserRole": "2",
                            "IsActivated": this.isActivatedUser ? "1": "0",
                            "GroupId": this.selectedGroup
                        }).then(async () => { 
                            //Add User Group
                            await ACLService.methods.addUserGroup({
                                "id": Utils.methods.uuidv4(),
                                "UserGroupId": Utils.methods.uuidv4(),
                                "UserId": userId,
                                "GroupId": this.selectedGroup
                            }).then(() =>{
                                this.loaderVisible = false
                                this.showAlert(1, 'User Permission Created Successfully')
                            }) 
                            .catch(() =>{
                                this.loaderVisible = false
                                this.showAlert(null, 'User Permission not Created')
                            })
                        })
                        this.loaderVisible = false
                        this.clearAll()
                        await this.loadTableData()
                    } else {
                        this.showAlert(null, 'User Already exists')
                    }
                }
            })
        },
        editUserDetails(userDetails) {
            this.loaderVisible = true
            this.userEmail = userDetails.UserEmail
            this.selectedGroup = (this.groupList.find(group => group.GroupName === userDetails.GroupName)).GroupId
            this.isActivatedUser = userDetails.IsActivated
            this.userId = userDetails.UserId
            this.showAddButton = false
            this.loaderVisible = false
        },
        updateUserDetails () {
            //check validation
            this.$v.$touch()
            if(this.$v.$invalid) {
                return
            }
            //modal 
            this.$bvModal.msgBoxConfirm('Are you sure you want to update user')
            .then(async result => {
                if(result) {
                    // check for already updated User
                    let user = this.userList.find(userItem => userItem.UserEmail === this.userEmail.trim() && userItem.UserID !== this.userId)
                    if(!user) {
                        this.loaderVisible = true
                        // update user data for changing email and Checkbox
                        await ACLService.methods.updateUser({
                            "id": (this.userList.find(ul => ul.UserID === this.userId)).id,
                            "UserEmail": this.userEmail.trim(),
                            "IsActivated": this.isActivatedUser === true || this.isActivatedUser === 'true' ? "1": "0"
                        }).then( async () => {
                            // update user group for group change
                            await ACLService.methods.updateUserGroup({
                                "id": (this.userGroupList.find(ul => ul.UserId === this.userId)).id,
                                "GroupId": this.selectedGroup
                            }).then(() => {
                                this.loaderVisible = false
                                this.showAlert(1, 'User Permission Updated Successfully')
                            })
                            .catch(() =>{
                                this.loaderVisible = false
                                this.showAlert(null, 'User permission not updated')
                            })                            
                        })
                        this.clearAll()
                        await this.loadTableData()
                    } else {
                        this.showAlert(null, 'Datas are already available in the User Permission')
                    }
                    this.loaderVisible = false 
                }
            })
        },
        deleteUserDetails(userDetails) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to Delete the User Permission ?')
            .then(async result => {
                if(result) {
                    this.loaderVisible = true
                    // delete user => IsActivated = false
                    await ACLService.methods.deleteUser({
                        "id": (this.userList.find(item => item.UserID === userDetails.UserId)).id,
                        "IsActivated": "0"
                    }).then( () => {
                        this.loaderVisible = false
                        this.showAlert(1, 'User permission deleted Successfully')
                    })
                    .catch(() =>{
                        this.loaderVisible = false
                        this.showAlert(null, 'User Permission not deleted')
                    })
                    this.clearAll()
                    await this.loadTableData()
                    this.loaderVisible = false
                }
            })
        },
        clearAll() {
            this.userEmail = null
            this.selectedGroup = null
            this.isActivatedUser = false
            this.showAddButton = true
            this.$v.$reset()
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    computed : {
        ...mapGetters(['getRightsList', 'getUserList', 'getUserGroupList', 'getGroupList', 'getGroupRightsList']),
        selectedGroupRightsList() {
            let rightsList = []
            if(this.selectedGroup) {
                let groupRights = this.groupRightsList.filter(groupRightsItem => groupRightsItem.groupId === this.selectedGroup)
                groupRights.forEach(item => {
                    let rightsItem = this.rightsList.find(right => right.RightId === item.rightId)
                    if(rightsItem) {
                        rightsList.push(rightsItem)
                    }
                })
            }
            return rightsList
        },
        paginationTotalRecords() {
            return this.userList?.length ?? 0
        },
        tableData() {
            let tableData = [], _tableData = []
            if(this.userList && this.userList.length > 0) {
                tableData = JSON.parse(JSON.stringify(this.userList))
                if(this.isLazyLoadingEnabled) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    tableData = tableData.slice((selectedPage - 1)* 10, selectedPage * 10)
                }
                
                if(tableData.length > 0) {
                    // generate table data 
                    tableData.forEach(user => {
                        let groupId = (this.userGroupList.find(item => item.UserId === user.UserID)).GroupId
                        if(user.IsActivated === "1") {
                            _tableData.push({
                                'UserRole': "2",
                                'UserEmail': user.UserEmail,
                                "GroupName": (this.groupList.find(item => item.GroupId === groupId)).GroupName,
                                "IsActivated": "true",
                                "UserId": user.UserID
                            })
                        } else {
                            _tableData.push({
                                'UserRole': "2",
                                'UserEmail': user.UserEmail,
                                "GroupName": (this.groupList.find(item => item.GroupId === groupId)).GroupName,
                                "IsActivated": "false",
                                "UserId": user.UserID
                            })
                        }
                    })
                }
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.userList?.length ?? 0
                }
            }
            this.isLazyLoadingEnabled ? updateTotalRecords() : null
            return _tableData
        }
    },
    async created () {
        this.loaderVisible = true
        if(!this.getRightsList || this.getRightsList.length < 1) await this.fetchRightsList()
        await this.fetchGroupRightsList()
        this.rightsList = await this.getRightsList
        this.groupRightsList = await this.getGroupRightsList
        await this.loadTableData() 
        this.loaderVisible = false      
    },
    validations : {
        userEmail : {required,email},
        selectedGroup : {required}
    }
 }
</script>